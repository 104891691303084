.container {
  height: 60px;
  width: auto;
}

.character {
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #50C0FF;
  font-size:24px;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.50);
  border-radius: 16px;
  border: 1px solid #1EA7E4;
}

.character--selected {
  box-shadow: 0px 0px 32px #0093D1;
  outline: none;
}

.character--inactive {
}

@media only screen and (max-width: 768px) {
  .character {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }
}
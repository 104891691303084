.Toastify__toast-container--top-right {
  top: 72px;
}

.Toastify__toast-container {
  padding: 0;
  width: 420px;
}

.Toastify__toast {
  padding: 0;
  border-radius: 8px;
  overflow: visible;
}

.Toastify__toast-body {
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 90vw;
    left: auto;
    right: 12px;
  }
}
/* .scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
} */

.scale-out-center {
  -webkit-animation: sscale-out-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation:scale-out-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-10-13 11:44:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */

 /* ----------------------------------------------
 * Generated by Animista on 2023-10-18 11:23:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 /**
 * ----------------------------------------
 * animation scale-down-tr
 * ----------------------------------------
 */
 @-webkit-keyframes scale-out-tr {
   0% {
     -webkit-transform: scale(0);
     transform: scale(0);
     -webkit-transform-origin: 100% 0%;
     transform-origin: 100% 0%;

   }

   100% {
     -webkit-transform: scale(1);
     transform: scale(1);
     -webkit-transform-origin: 100% 0%;
     transform-origin: 100% 0%;
   }
 }

 @keyframes  scale-out-tr {
   0% {
    -webkit-transform: scale(0);
      transform: scale(0);
     -webkit-transform-origin: 100% 0%;
     transform-origin: 100% 0%;
    
   }

   100% {
    -webkit-transform: scale(1);
      transform: scale(1);
     -webkit-transform-origin: 100% 0%;
     transform-origin: 100% 0%;
   }
 }


.scale-in-tr {
  -webkit-animation: scale-down-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  animation: scale-down-tr 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
}

@-webkit-keyframes scale-down-tr {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes scale-down-tr {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scale(0);
      transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}